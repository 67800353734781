import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {

    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const currentUser = this.authService.currentUserValue;
        if (currentUser) {
            return true;
        }
        if (state.url && state.url.split('?')[0].includes('sign')) {
            this.router.navigate(['/login/register'], { queryParams: { returnUrl: state.url.split('?')[0] } });
            return false;
        }
        const baseUrl = state.url.split('?')[0];
        this.router.navigate(['/login'], { queryParams: { returnUrl: baseUrl } });
        return false;

        // https://app.foodchecker.com.br/payments/sign/cart/mensal?affid=ad&utm_source=facebook&utm_medium=feed&utm_campaign=lukas
    }
}
